import { Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FxButton } from '../../../Action/FxButton';
import FxCheckEdit from '../../../Input/FxCheck/FxCheckEdit';
import { FxCongratulations } from '../../../Utils/FxCongratulations';
import { FxPdfReader } from '../../SignUp/Business/FxPdfReader';
import { OnboardingLayout } from '../Layouts/OnboardingLayout';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { updateComponentParams } from '../../../Utils/CommonBaseClass';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { setKey, setUserLoggedIn } from '../../../../libs/utils/storageManager';
import FxSnackBar from '../../../Utils/fx-snack-bar';
import HttpClient from '../../../../libs/utils/httpClient';

/**
 * Component used to display partner agreement
 */
export const PartnerAgreement: React.FC<any> = React.memo((props) => {
  ({ props } = RegisterComponent(props));
  const dispatch = useDispatch()
  const history = useHistory();

  const { register, setValue, control } = useForm();
  const { isSubmitDisabled, isAccepted, fileInfo, newpayload, isLoading } = props;

  useEffect(() => {
    updateComponentParams(dispatch, props?.id, { isSubmitDisabled: true, isLoading: false })
  },[dispatch,props?.id])

  const agreementCheckboxData = [
    {
      label: (
        <span>By submitting this form, you accept the terms and conditions to enable Partner Account.
          <br />
          You also accept receiving electronic communications regarding your account, and you
          <br />
          certify that the information you provided is complete and accurate.
        </span>),
      value: 'yes',
      checked: false
    }
  ];

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement> | any) => {
    updateComponentParams(dispatch, props?.id, { isSubmitDisabled: !isSubmitDisabled })
  }

  const onAcceptClick = async () => {
    let req: any = { ...newpayload }
    if (fileInfo !== undefined) {
      req.linkedDocument = req.linkedDocument ? [...req.linkedDocument] : [];
      req.linkedDocument.push({
        "purpose": "PARTNER_AGREEMENT",
        "document": {
          "name": "End_user_license_agreement.pdf",
          "type": "PARTNER_AGREEMENT",
          "base64encodedContent": fileInfo
        }
      })
    }
    req["partnerAgreementAccepted"] = true
    updateComponentParams(dispatch, props?.id, { isLoading: true })

    const httpClient = HttpClient.getClient();
    const status = await httpClient.post('user/acceptPrivacyPolicy', req).then(response => {
      setUserLoggedIn();
      setKey("showTerms", "FALSE")
      return response
    }).catch(errorss => {
      setKey("showTerms", "FALSE")
      return errorss.response
    })
    updateComponentParams(dispatch, props?.id, { isLoading: false })
    if (status) {
      updateComponentParams(dispatch, 'layout-passport', { 'showTerms': 'NO' })
      //auth success
      FxSnackBar.show({
        autoHideDuration: 1000,
        severity: 'success',
        text: 'Logged in successfully',
      });
      updateComponentParams(dispatch, "layout-passport", { 'selectedPage': 'LEADS' })
      updateComponentParams(dispatch, props?.id, { 'isAccepted': true })
    }
    else {
      //auth failed
      FxSnackBar.show({
        text: 'Please try again',
      });
    }
  }

  const onContinueButtonClick = () => {
    history.push('/leads-home')
  }

  const setFileInfo = (data: any) => {
    updateComponentParams(dispatch, props?.id, { 'fileInfo': data })
  }

  return (
    <OnboardingLayout title={'Passport Partner Agreement'}>
      {!isAccepted ? <Grid item xs={9}>
        <Grid item xs={12} marginBottom={2}>
          <Typography variant="h3" className={'fx-partner-agreement-header'}>Passport Partner Agreement</Typography>
        </Grid>
        <Grid className="fx-partner-agreement-card">
          <Grid className="fx-partner-agreement-document">
            <FxPdfReader
              url={'/assets/pdf/passport_account_agreement.pdf'} onFileInfoReady={(data) => setFileInfo(data)}
            />
          </Grid>
          <Grid className={'fx-submit-agree'}>
            <FxCheckEdit register={{ ...register('agreementCheckbox') }}
              rules={{ required: 'Please click Agree' }}
              control={control}
              id="partner-agreement-chackbox"
              name="agreementCheckbox"
              className="fx-privacy-text" data={agreementCheckboxData}
              row="vertical"
              onChange={(e: any) => {
                handleCheckboxChange(e);
              }}
              setValue={setValue} />

          </Grid>
          <Grid item textAlign={'right'} className={'fx-submit-btn'}>
            <FxButton id={'accept-partner-aggrement-button'} className={`${isSubmitDisabled ? 'fx-button-disabled' : 'fx-button-theme'}`} disabled={isSubmitDisabled} onClick={onAcceptClick} isSubmitting={isLoading}>Accept</FxButton>
          </Grid>
        </Grid>
      </Grid>
        :
        <Grid item xs={6} className={'fx-partner-congratulations-card'}>
          <FxCongratulations
            buttonText={'Continue to Dashboard'}
            message={'Your Partner account application has been successfully submitted and is currently under review.'}
            onButtonClick={onContinueButtonClick}
          />
        </Grid>}
    </OnboardingLayout>
  )
})
